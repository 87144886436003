import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBlogArticleCTACard, LazyCodeTabs, LazyGitHubCodeSnippet, LazyAuthBlokToggle, LazyEventSection, LazyAnnouncementBanner, LazyCobrandedHomePageHeader, LazyCobrandedHomePageUseCasesSection, LazyVonageHomePageBlogSection, LazyVonageHomePageCallToActionSection, LazyVonageHomePageCommunityStatsSection, LazyVonageHomePageCustomerLogosSection, LazyVonageHomePageHeaderSection, LazyVonageHomePageJoinCommunitySection, LazyVonageHomePageLanguagePickerSection, LazyVonageHomePageProductsSection, LazyVonageHomePageProjectsSection, LazyBlogArticle, LazyBlogAuthor, LazyCobrandedHomePage, LazyVonageHomePage, LazyResolveRelations } from '#components'
const lazyGlobalComponents = [
  ["BlogArticleCTACard", LazyBlogArticleCTACard],
["CodeTabs", LazyCodeTabs],
["GitHubCodeSnippet", LazyGitHubCodeSnippet],
["AuthBlokToggle", LazyAuthBlokToggle],
["EventSection", LazyEventSection],
["AnnouncementBanner", LazyAnnouncementBanner],
["CobrandedHomePageHeader", LazyCobrandedHomePageHeader],
["CobrandedHomePageUseCasesSection", LazyCobrandedHomePageUseCasesSection],
["VonageHomePageBlogSection", LazyVonageHomePageBlogSection],
["VonageHomePageCallToActionSection", LazyVonageHomePageCallToActionSection],
["VonageHomePageCommunityStatsSection", LazyVonageHomePageCommunityStatsSection],
["VonageHomePageCustomerLogosSection", LazyVonageHomePageCustomerLogosSection],
["VonageHomePageHeaderSection", LazyVonageHomePageHeaderSection],
["VonageHomePageJoinCommunitySection", LazyVonageHomePageJoinCommunitySection],
["VonageHomePageLanguagePickerSection", LazyVonageHomePageLanguagePickerSection],
["VonageHomePageProductsSection", LazyVonageHomePageProductsSection],
["VonageHomePageProjectsSection", LazyVonageHomePageProjectsSection],
["BlogArticle", LazyBlogArticle],
["BlogAuthor", LazyBlogAuthor],
["CobrandedHomePage", LazyCobrandedHomePage],
["VonageHomePage", LazyVonageHomePage],
["ResolveRelations", LazyResolveRelations],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
